<template>
  <!-- 代理商新增，修改 -->
  <div>
    <el-dialog append-to-body title="编辑" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="服务类型" required prop="type">
          <el-radio v-model="form.type" :label="1">闲时</el-radio>
          <el-radio v-model="form.type" :label="2">高峰</el-radio>
        </el-form-item>
         <el-form-item label="场次时间" required prop="duration">
          <el-radio v-model="form.duration" :label="1">小时</el-radio>
          <el-radio v-model="form.duration" :label="1.5">90分钟</el-radio>
          <el-radio v-model="form.duration" :label="2">2小时</el-radio>
        </el-form-item>
         <el-form-item label="预约方式" required prop="service_type">
          <el-radio v-model="form.service_type" :label="1">整场</el-radio>
          <el-radio v-model="form.service_type" :label="2">人数</el-radio>
        </el-form-item>

        <el-form-item label="场地容量" required prop="count">
          <el-input
            v-model="form.count"
            type="number"
            placeholder="场地容量"
          ></el-input>
          <p v-if="form.service_type == 1">场地最多可同时容纳的人数</p>
          <p v-if="form.service_type == 2">
            场地最多可同时开放的场次数，如足球场可约场次为1
          </p>
        </el-form-item>
        <el-form-item label="允许教学">
          <el-radio-group v-model="form.teaching">
            <el-radio :label="false">不允许</el-radio>
            <el-radio :label="true">允许</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="共享价格" >
          <el-input
            v-model="form.price"
            type="number"
            placeholder="价格"
            @blur="getName"
          ></el-input>
        </el-form-item>
         <el-form-item label="服务名称" prop="name">
          <el-input v-model="form.name" placeholder=""></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="form.id"
          type="primary"
          @click="updateForm"
          :loading="loading"
          >更 新</el-button
        >
        <el-button v-else type="primary" :loading="loading" @click="addForm"
          >新 增</el-button
        >
      </span>

    </el-dialog>
  </div>
</template>
<script>
import { ObjectChangedValue } from "@/js/utils.js";
export default {
  data() {
    return {
      rules: {
        type: [
          {
            required: true,
            message: "请选择类型",
            trigger: ["change"],
          },
        ],
         duration: [
          {
            required: true,
            message: "请选择时长",
            trigger: ["change"],
          },
        ],
         service_type: [
          {
            required: true,
            message: "请选择服务方式",
            trigger: ["change"],
          },
        ],
         count: [
          {
            required: true,
            message: "请填写价格",
            trigger: ["change"],
          },
        ]
      },
      clear: false,
      form: {service_type:'',name:'',count:''},
      temp: {},
      dialogVisible: false,
      loading: false,
     device:{}
    };
  },

  methods: {
    getName(){
            let name = `${this.form.service_type == 1 ? '整场':'人数'}-${this.form.type == 1 ? '闲':'忙'}-${this.form.duration}h-${this.form.price}元`
			this.form.name = name
    },
    addForm() {
        this.form.categories = this.form.type == 1 ? this.device.share_categories : this.device.busy_share_categories
		this.form.venue_id = this.device.venue_id
		this.form.device_id = this.device.id
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$axios({
            url: "/shared/deviceService/insert",
            method: "post",
            data: this.form,
          })
            .then((res) => {
              this.$notify({
                message: "新增成功",
                duration: 2000,
                type: "success",
              });
              this.dialogVisible = false;
              this.loading = false;
              this.$emit("success", 1);
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },

    updateForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = ObjectChangedValue(this.temp, this.form);
          if (Object.keys(data).length == 0) {
            this.$message("没有修改数据，无需更新");
            return;
          }
          this.loading = true;
          this.$axios({
            url: "/shared/deviceService/update",
            method: "post",
            data: {
              id: this.form.id,
              ...data,
            },
          })
            .then((res) => {
              this.$notify({
                message: "更新成功",
                duration: 2000,
                type: "success",
              });
              this.dialogVisible = false;
              this.loading = false;
              this.$emit("success", 1);
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },

    open(info,device) {
      if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = info;
      } else {
        this.form =  {service_type:'',name:'',count:''}
        this.temp = { };
      }
      if(device){
        this.device = device
        if(this.device.type == 'person'){
            this.form.service_type = 2
        }else{
            this.form.service_type = 1
        }
        this.form.count = this.device.max_people
        this.form.teaching = this.device.teaching
      }
      this.dialogVisible = true;
    },

  },
};
</script>

<style lang="less" scoped>
</style>