<template>
  <div class="">
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false">
    <el-form :inline="true">
      <el-form-item>
        <el-button type="success" @click="openAdd">新增场地服务</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #status="{ row }">
        <div>
           <el-tag v-if="row.status === 1">上架中</el-tag>
            <el-tag type="danger" v-if="row.status === 2">下架</el-tag>
        </div>
      </template>
      <template #handler="{ row }">
        <div>
           <el-button type="primary" size="small" @click="openEdit(row)">修改</el-button>
        </div>
      </template>
    </auto-table>

     <editService ref="add" @success="succ"></editService>
    </el-dialog>
  </div>
</template>

<script>
import editService from './editService.vue';

export default {
    components:{
        editService,
    },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "名称", value: "name" },
        { name: "容量", value: "count" },
        { name: "添加时间", value: "create_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      status: 1,
      dialogVisible:false,
      device:{}
    };
  },

  mounted() {
  },

  methods: {
    succ(){
            this.getList(1)
            this.$emit('change',{})
    },
    init(device){
        this.device = device
        this.dialogVisible = true
         this.getList(1);
    },
    openAdd() {
       let comm =  { venue_id: this.id,}
          this.$refs.add.open(comm,this.device )
    },
     openEdit(row) {
         this.$refs.add.open(row)
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/shared/deviceService/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          device_id:this.device.id
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>